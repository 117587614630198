import cookie from 'react-cookies';
import {AUTH, AUTH_LOGOUT, AUTH_USER} from '../constants';

export default (state = {}, action) => {
  switch (action.type) {
    case AUTH:
      const { data } = action.payload;
      if(data && data.jwt) {
        cookie.save('token', data.jwt, {path: '/'});
        window.location = '';
      }
      return null;
    case AUTH_LOGOUT:
      cookie.remove('token', {path: '/'});
      return null;
    case AUTH_USER:
      return action.payload.data;
    default:
      return state;
  }
};
