import React from 'react';
import { connect } from 'react-redux';
import { login } from '../../actions/user';

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.username = React.createRef();
    this.password = React.createRef();
  }

  handleSubmit(e) {
    e.preventDefault();
    const data = {
      username: this.username.current.value,
      password: this.password.current.value,
    };
    this.props.login(data);
  }

  render() {
    return (
      <div>
        <h1>Вход в системата</h1>
        <form onSubmit={(e) => this.handleSubmit(e)}>
          <div className="form-group">
            <label htmlFor="username">Потребител</label>
            <input ref={this.username} type="text" className="form-control" id="username" />
          </div>
          <div className="form-group">
            <label htmlFor="password">Парола</label>
            <input ref={this.password} type="password" className="form-control" id="password" />
          </div>
          <button type="submit" className="btn btn-primary">Вход</button>
        </form>
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
}

const mapDispatchToProps = {login}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login)
