import { combineReducers } from 'redux';
import user from './user';
import orders from './orders';
import order from './order';
import update from './update';
import update_timer from './update_timer';
import history from './history';

export default combineReducers({
  user,
  orders,
  order,
  update,
  update_timer,
  history,
});
