import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import axios from 'axios';
import cookie from 'react-cookies'
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import configureStore from './store/configureStore';
import { getUser, logout } from './actions/user';
require('dotenv').config()
const headers = {};
if (cookie.load('token')) {
  headers.Authorization = `Bearer ${cookie.load('token')}`;
}

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_ENTRYPOINT || 'http://localhost:8001/',
  mode: 'no-cors',
  headers,
});

const store = configureStore({}, {
  axios: axiosInstance,
});

store.dispatch(getUser());

axiosInstance.interceptors.response.use(response => {
  return response;
}, error => {
  if (error.response && error.response.status === 401) {
    store.dispatch(logout());
  }
  return error;
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
