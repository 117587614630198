import {FETCH_UPDATE} from '../constants';

export default (state = {}, action) => {
  switch (action.type) {
    case FETCH_UPDATE:
      if(action.payload.data && JSON.stringify(state) !== JSON.stringify(action.payload.data))
        return action.payload.data
      return state;
    default:
      return state;
  }
};
