import {SET_UPDATE_TIMER, UPDATE_TIMER} from "../constants";

export const updateTimer = () => async (dispatch) => {
  dispatch({
    type: UPDATE_TIMER,
    payload: null,
  });
};

export const setTimer = value => async (dispatch) => {
  dispatch({
    type: SET_UPDATE_TIMER,
    payload: value,
  });
};
