export const AUTH = 'AUTH';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_USER = 'AUTH_USER';

export const FETCH_ORDERS = 'FETCH_ORDERS';
export const ADD_ORDER = 'ADD_ORDER';

export const FETCH_UPDATE = 'FETCH_UPDATE';

export const SET_UPDATE_TIMER = 'SET_UPDATE_TIMER';
export const UPDATE_TIMER = 'UPDATE_TIMER';

export const ADD_HISTORY = 'ADD_HISTORY';
