import { AUTH, AUTH_LOGOUT, AUTH_USER } from '../constants';

export const login = data => async (dispatch, getState, { axios }) => {
  const response = await axios.post(`/auth`, data);
  dispatch({
    type: AUTH,
    payload: response,
  });
};

export const getUser = data => async (dispatch, getState, { axios }) => {
  const response = await axios.get(`/auth`, data);
  dispatch({
    type: AUTH_USER,
    payload: response,
  });
};

export const logout = () => async (dispatch) => {
  dispatch({
    type: AUTH_LOGOUT,
    payload: null,
  });
};