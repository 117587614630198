import {SET_UPDATE_TIMER, UPDATE_TIMER} from '../constants';

export default (state = 0, action) => {
  switch (action.type) {
    case UPDATE_TIMER:
      let timer = state - 1;
      if(timer < 0) timer = 0;
      return timer;
    case SET_UPDATE_TIMER:
      return action.payload;
    default:
      return state;
  }
};
