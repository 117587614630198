import React from 'react';
import { connect } from 'react-redux';
import {fetchOrders, updateOrder} from '../../actions/order';
import {setHistory} from '../../actions/history';

Number.prototype.lpad = function(padString, length) {
  let str = this + '';
  while (str.length < length)
    str = padString + str;
  return str;
}

class OrderList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      orderId: null,
      confirmSend: false,
      history: false,
      historyDate: new Date(),
    };
    this.historyRef = React.createRef();
    this.priority = {};
    this.sendOrder = this.sendOrder.bind(this);
    this.switchHistory = this.switchHistory.bind(this);
  }
  componentDidMount() {
    this.props.fetchOrders(this.props.history);
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if(
      (prevProps.update.order
        && this.props.update.order
        && prevProps.update.order !== this.props.update.order
      )
      || prevProps.order !== this.props.order
      || prevProps.history !== this.props.history
    )
      this.props.fetchOrders(this.props.history);
  }

  handleFocus(e) {
    e.target.select();
  }

  renderActions(order) {
    const { history } = this.state;
    const staticActions = [];
    let priority;
    if(!this.priority[order.id])
      this.priority[order.id] = React.createRef();
    if(!history) {
      staticActions.push(<div key={1}>
        <button
          onClick={() => this.setState({orderId: order.id, confirmSend: true})}
          className="btn btn-info"
        >Изпратена
        </button>
      </div>);
      priority = (
        <div className="input-group">
          <input onFocus={(e) => this.handleFocus(e)} ref={this.priority[order.id]} type="number" defaultValue={order.priority} min={0} max={99} step={1} className="form-control"/>
          <div className="input-group-append">
            <button
              onClick={() => this.props.updateOrder({
                id: order.id,
                priority: this.priority[order.id].current.value,
              })}
              className="btn btn-warning"
            >Приоритет</button>
          </div>
        </div>
      );
    }else{
      priority = <span>{order.priority}</span>;
    }
    return (
      <div className="order-item-actions">
        {priority}
        {staticActions.map(e => e)}
      </div>
    );
  }

  renderOrder(order) {
    return (
      <li key={order.id} className="list-group-item order-item">
        <div className="d-flex justify-content-between order-item-header">
          {this.renderActions(order)}
        </div>
        <table className="table order-item-data">
          <tbody>
            <tr>
              <td>Добавена</td>
              <td>{order.added_time}</td>
            </tr>
            <tr>
              <td>Доставка</td>
              <td>{order.delivery_time}</td>
            </tr>
            <tr>
              <td>Адрес</td>
              <td>{order.address}</td>
            </tr>
            <tr>
              <td>Сума</td>
              <td>{order.total/100}лв.</td>
            </tr>
          </tbody>
        </table>
      </li>
    );
  }

  renderConfirmSend(text, approve) {
    const { confirmSend } = this.state;
    if(!confirmSend) return null;
    return (
      <div>
        <div onClick={() => this.setState({confirmSend: false})} className="pop-up-fade"/>
        <div className="pop-up">
          <div>
            <p>{text}</p>
            <div>
              <button className="btn btn-danger m-3" onClick={() => this.setState({confirmSend: false})}>Не</button>
              <button className="btn btn-success m-3" onClick={() => this.sendOrder()}>Да</button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  sendOrder() {
    this.props.updateOrder({
      id: this.state.orderId,
      send: true,
    });
    this.setState({
      orderId: null,
      confirmSend: false,
    });
  }

  switchHistory(event, history) {
    this.setState({history});
    if(history){
      this.props.setHistory(this.historyRef.current.value);
    }else{
      this.props.setHistory(null);
    }
    event.target.blur();
  }

  render() {
    const {history,historyDate} = this.state;
    return (
      <div>
        {this.renderConfirmSend('Сигурни ли сте?')}
        <div className="row">
          <div className="col col-sm-6 col-12">
            <button className={history ? 'btn bnt-default' : 'btn btn-primary'} onClick={(e) => this.switchHistory(e, false)}>Текущи поръчки</button>
          </div>
          <div className="col col-sm-6 col-12">
            <div className="input-group">
              <input
                ref={this.historyRef}
                type="date"
                pattern="\d{4}-\d{2}-\d{2}"
                defaultValue={`${historyDate.getFullYear()}-${(historyDate.getMonth() + 1).lpad('0',2)}-${historyDate.getDate().lpad('0',2)}`}
                className="form-control"
              />
              <div className="input-group-append">
                <button className={!history ? 'btn bnt-default' : 'btn btn-primary'} onClick={(e) => this.switchHistory(e, true)}>История</button>
              </div>
            </div>
          </div>
        </div>
        <ul className="list-group">
          {this.props.orders.map(o => this.renderOrder(o))}
        </ul>
      </div>
    );
  }
}

const mapStateToProps = ({orders, order, update, history}) => {
  return {
    orders,
    order,
    update,
    history,
  };
}

const mapDispatchToProps = {fetchOrders, updateOrder, setHistory}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderList)
