import {FETCH_UPDATE} from "../constants";


export const fetchUpdate = () => async (dispatch, getState, { axios }) => {
  const response = await axios.get(`/update`);
  dispatch({
    type: FETCH_UPDATE,
    payload: response,
  });
};
