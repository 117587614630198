import React from 'react';
import { connect } from 'react-redux'
import './App.css';
import Login from './components/Login';
import Header from './components/Header';
import OrderList from './components/OrderList';
import OrderAdd from './components/OrderAdd';
import {fetchUpdate} from './actions/update';
import {updateTimer, setTimer} from './actions/update_timer';

class App extends React.Component {
  UPDATE_INTERVAL = 15000;

  componentDidMount() {
    this.props.fetchUpdate();
    this.interval = setInterval(() => this.checkForUpdates(), this.UPDATE_INTERVAL);
    this.props.setTimer(this.UPDATE_INTERVAL/1000);
    this.intervalTimer = setInterval(() => this.props.updateTimer(), 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    clearInterval(this.intervalTimer);
  }

  checkForUpdates() {
    this.props.setTimer(this.UPDATE_INTERVAL/1000);
    this.props.fetchUpdate();
  }

  render() {
    let module;
    if(this.props.user && this.props.user.id){
      module = (
        <div>
          <Header />
          <OrderAdd />
          <OrderList />
        </div>
      );
    }else{
      module = <Login />;
    }
    return (<div className="container">
      {module}
    </div>);
  }
}

const mapStateToProps = ({ user }) => {
  return {
    user: user,
  }
}

const mapDispatchToProps = {fetchUpdate, updateTimer, setTimer}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App)
