import React from 'react';
import { connect } from 'react-redux';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import moment from 'moment';
import { addOrder } from '../../actions/order';

class OrderAdd extends React.Component {
  constructor(props) {
    super(props);

    this.text = React.createRef();
    this.parseFields = [
      {
        name: 'added',
        title: 'Добавена',
        type: 'time',
        parser: (text, target) => {
          const re = /\d+:\d+:\d+/;
          const m = text.match(re);
          if(m && m.length)
            target.current.value = m[0];
        },
        ref: React.createRef(),
      },
      {
        name: 'delivery',
        title: 'Доставка',
        type: 'time',
        parser: (text, target) => {
          const re = /\d+:\d+:\d+/;
          const m = text.match(re);
          if(m && m.length)
            target.current.value = m[0];
        },
        ref: React.createRef(),
      },
      {
        name: 'address',
        title: 'Адрес',
        type: 'text',
        parser: (text, target) => target.current.value = text,
        ref: React.createRef(),
      },
      {
        name: 'total',
        title: 'Сума',
        type: 'number',
        step: 0.01,
        parser: (text, target) => {
          const re = /[^\d]+/g;
          target.current.value = text.replace(re, '');
        },
        ref: React.createRef(),
      },
    ];

    this.state = {
      showForm: false,
    };

    this.handleText = this.handleText.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleText() {
    const fields = this.parseFields;
    this.text.current.value.split(/\r\n|\r|\n/).forEach(function (row, idx) {
      if(this.parseFields[idx]){
        fields[idx].parser(row, fields[idx].ref);
      }
    }.bind(this));
  }

  handleSubmit(e) {
    e.preventDefault();
    const data = this.parseFields.reduce((map, elm) => {
      const value = elm.ref.current.value;
      map.data[elm.name] = value;
      if(value.length <= 0) {
        map.valid = false;
      }
      return map;
    }, {valid: true, data: {}});
    if(data.valid) {
      this.props.addOrder(data.data);
      this.parseFields.forEach((elm) => elm.ref.current.value = '');
      // this.text.current.value = '';
      this.setState({showForm: false});
    }
  }

  renderForm() {
    const { showForm } = this.state;
    return (
      <form onSubmit={(e) => this.handleSubmit(e)}>
        <div className="row">
          {/*<div className="col col-md-6 col-sm-12">*/}
          {/*  <textarea*/}
          {/*    onKeyUp={() => this.handleText()}*/}
          {/*    onInput={() => this.handleText()}*/}
          {/*    ref={this.text} rows="5"*/}
          {/*    className="form-control"*/}
          {/*    placeholder="Текст на поръчката" />*/}
          {/*</div>*/}
          <div className="col col-md-6 col-sm-12">
            {this.parseFields.map((f) => <div key={f.name}>
              <label htmlFor={f.name}>{f.title}</label>
                {
                  f.type === 'time' ?
                    (
                      <div>
                        <input type="hidden" ref={f.ref} defaultValue={moment().format('HH:mm')}/>
                        <TimePicker onChange={(e) => f.ref.current.value = e.format('HH:mm')} defaultValue={moment()} showSecond={false} />
                      </div>
                    ):
                  <input step={f.step || null} ref={f.ref} type={f.type} id={f.name} placeholder={f.title} className="form-control"/>
                }
              </div>
              )}
          </div>
        </div>
        <div className="row">
          <div className="col text-right">
            <button onClick={() => this.setState({showForm: !showForm})} className="btn btn-danger m-2">Откажи</button>
            <button type="submit" className="btn btn-success m-2">Добави</button>
          </div>
        </div>
      </form>
    );
  }

  renderFormBtn() {
    const {showForm} = this.state;
    return (
      <div className="text-center">
        <button onClick={() => this.setState({showForm: !showForm})} className="btn btn-warning">Нова поръчка</button>
      </div>
    );
  }

  render() {
    const {showForm} = this.state;
    return (
      <div className="container mt-3 mb-3">
        <div className="row">
          <div className="col col-12">
            {showForm ? this.renderForm() : this.renderFormBtn()}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
}

const mapDispatchToProps = {addOrder}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderAdd)
