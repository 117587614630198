import { FETCH_ORDERS, ADD_ORDER } from '../constants';

export const fetchOrders = history => async (dispatch, getState, { axios }) => {
  const response = await axios.get(`/orders?history=${history}`);
  dispatch({
    type: FETCH_ORDERS,
    payload: response,
  });
};

export const addOrder = data => async (dispatch, getState, { axios }) => {
  const response = await axios.post(`/orders`, data);
  dispatch({
    type: ADD_ORDER,
    payload: response,
  });
};

export const updateOrder = data => async (dispatch, getState, { axios }) => {
  const response = await axios.put(`/orders`, data);
  dispatch({
    type: ADD_ORDER,
    payload: response,
  });
};
